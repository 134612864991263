import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { createNotification } from '../../../utils/helpers';
import lang from '../../../langs';

import { updateSettings, getSettings } from '../../../actions/payment';
import { withRouter } from '../../../withRouter';

const requiredFields = ['sgd'];

class FormPaymentSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
    };
    this.handleSave = this.handleSave.bind(this);
  }

  updateState(name, value) {
    this.setState({
      settings: {
        ...this.state.settings,
        [name]: value,
      },
    });
  }

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      try {
        await formValidation(this.state.settings, requiredFields);
      } catch (err) {
        this.setState({ errors: err });
        createNotification(
          'error',
          _.map(err, (val) => {
            return val;
          }).join('\n\n\n'),
        );
        return false;
      }
      let updateSettings = { ...this.state.settings };
      await this.props.updateSettings(updateSettings);
      createNotification('success', lang('success.update'));
    }
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.getSettings();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.settings !== this.props.settings) {
      this.setState({ settings: nextProps.settings.data });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">
              <a href="#!">Payment</a>
            </li>
          </ol>
        </nav>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <h2 className="text-bold text-body-emphasis mb-5">Settings</h2>
        <form class="row g-3 needs-validation" onSubmit={this.handleSave} noValidate="">
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputCurrencyExchange">
              USD to SGD rate
            </label>
            <input
              class="form-control"
              id="inputCurrencyExchange"
              type="text"
              required="required"
              value={this.state.settings?.sgd}
              onChange={(e) => this.updateState('sgd', e.target.value)}
            />
            <div class="invalid-feedback">Please enter USD to SGD Rate</div>
          </div>
          <div class="col-md-12">
            <label class="form-label" htmlFor="inputfeechargeseller">
              Fee Charge Seller
            </label>
            <input
              class="form-control"
              id="inputfeechargeseller"
              type="text"
              value={this.state.settings?.fee_charge_seller}
              onChange={(e) => this.updateState('fee_charge_seller', e.target.value)}
            />
          </div>
          <div class="col-12">
            <button class="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  }
}

FormPaymentSettings.propTypes = {
  auth: PropTypes.object.isRequired,
  updateSettings: PropTypes.func.isRequired,
  getSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  settings: state.paymentMethods?.settings,
  loaded: state.loadingBar.sectionBar === 1 ? true : false,
});

export default connect(mapStateToProps, {
  updateSettings,
  getSettings,
})(withRouter(FormPaymentSettings));
